<template>
  <div class="ps-section__right">
    <form class="ps-form--account-setting" method="get">
      <div class="ps-form__header">
        <h3>Edit Prescription</h3>
      </div>
      <PrescriptionForm ref="prescriptionForm" :prescription-id="this.$route.params.id"></PrescriptionForm>
    </form>

  </div>
</template>

<script>
import PrescriptionForm from "./PrescriptionForm";
export default {
  name: "EditPrescription",
  components: { PrescriptionForm },
  data() {
    return {
      prescriptionSaved: false,
      isLoading: false,
      errors: null
    };
  },
}
</script>

<style scoped>

</style>