<template>
  <div class="ps-form__content">
    <div class="row mt-3 mb-3" v-if="message">
      <div class="col-12">
        <small class="text-danger" v-if="isError">{{ message }}</small>
        <small class="text-success" v-else>{{ message }}</small>
       </div>
    </div>
    <div class="form-group">
      <label>Code<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" type="text" v-model="code" placeholder="" readonly="readonly" />
      </div>
    </div>
    <div class="form-group">
      <label>Name<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" type="text" v-model="form.name" placeholder="" />
        <small v-if="errors && errors.name" class="phone_error text-danger">
          {{ errors.name[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Status<sup>*</sup> </label>
      <div class="form-group__content">
        <select class="form-control" v-model="form.status">
          <option value="1">Pending</option>
          <option value="2">Active</option>
        </select>
        <small v-if="errors && errors.status" class="text-danger">
          {{ errors.status[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <button class="ps-btn" @click.prevent="onSubmit()">
        {{ isLoading ? "Saving..." : "Save" }}
        <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
        <span v-if="isLoading" class="spinner-border text-light" role="status"></span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PrescriptionForm",
  props: {
    prescriptionId: String
  },
  data() {
    return {
      isLoading: false,
      form: {
        name: "",
        status: null
      },
      code: null,
      errors: null,
      message: null,
      isError: false
    }
  },
  created() {
     if (this.prescriptionId) {
        this.getPrescription();
     }
  },
  methods: {
      onSubmit() {
        if (this.prescriptionId) {
          this.updatePrescription()
        } else {
          this.createPrescription()
        }
      },

      updatePrescription() {
        this.isLoading = true;
        axios.put('prescriptions/' + this.prescriptionId, this.form).then((response) => {
          this.isLoading = false;
          if (response.status === 200) {
            this.message = 'Prescription successfully updated'
            this.$router.push({name: 'merchant_prescriptions'});
          }
        }).catch((error) => {
          if (error.response.status === 422) {
            this.message = "Prescription could not be updated. Please enter all required fields.";
            this.errors = error.response.data.errors
          } else {
            this.message = error.backendErrors[0]
          }
          this.isError = true
          this.isLoading = false;
        });
      },

      createPrescription() {
        axios.post('prescriptions', this.form).then((response) => {
          this.isLoading = false;
          if (response.status === 200 || response.status === 201) {
            this.message = 'Prescription successfully created'
            this.$router.push({name: 'merchant_addresses'});
          }
          this.isLoading = false;
        }).catch((error) => {
          if (error.response.status === 422) {
            this.message = "Prescription could not be created. Please enter all required fields.";
            this.errors = error.response.data.errors
          } else {
            this.message = error.backendErrors[0]
          }
          this.isError = true
          this.isLoading = false;
        });
      },

      getPrescription() {
        axios.get('prescriptions/' + this.prescriptionId).then((response) => {
          this.form = response.data;
          this.code = response.data.code;
          this.form.status = response.data.status.value;
        });
      }
  }
}
</script>

<style scoped>

</style>